import Vue from 'vue'

export default {
  FETCH_INDEXES({ commit }) {
    Vue.prototype.$httpUser.get('/api/indexes/vn')
      .then(resp => {
        commit('SET_EFFICIENCY', resp.data.data.efficiency)
      })
      .catch(() => {
        commit('SET_EFFICIENCY', {})
      })
  },
  FETCH_PORTFOLIOS({ commit }) {
    Vue.prototype.$httpUser.get('/api/portfolios')
      .then(resp => {
        commit('SET_PORTFOLIOS', resp.data.data)
      })
      .catch(() => {
        commit('SET_PORTFOLIOS', [])
      })
  },
}
