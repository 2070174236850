const Module = () => import('./Module.vue')
const Company = () => import('./views/Company.vue')
const Summary = () => import('./views/Summary.vue')

const moduleRoute = {
  path: '/company',
  redirect: 'company/filter',
  component: Module,
  children: [
    {
      path: 'filter',
      name: 'company',
      component: Company,
      meta: {
        is_pro: true,
      },
    },
    {
      path: 'summary/:symbol',
      name: 'company-summary',
      meta: {
        subTitleKey: 'symbol',
      },
      component: Summary,
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
