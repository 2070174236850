import Vue from 'vue'
import router from '@/router'

export default {
  LOGIN({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('/api/auth/user/login', user)
        .then(resp => {
          const { token } = resp.data
          commit('SET_TOKEN', token)
          localStorage.setItem('utkn', token)
          const channel = new BroadcastChannel('logged-in')

          dispatch('user/FETCH_USER', {}, { root: true })
            .then(userResp => {
              channel.postMessage({
                token,
                user: userResp.data,
              })
            })
          // Vue.prototype.$httpUser.bearer = token
          resolve(resp)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  LOGOUT({ commit }, redirect) {
    return new Promise(resolve => {
      commit('CLEAR_TOKEN')
      commit('user/SET_USER', null, { root: true })
      localStorage.clear()
      Vue.prototype.$httpUser.bearer = ''
      if (redirect) {
        router.push({ name: 'login' })
      }
      resolve()
    })
  },
}
