export default function setTitle(to) {
  let title = 'SimpleInvest Pro'
  if (to.meta.subTitle) {
    title = `${to.meta.subTitle} - ${title}`
  } else if (to.meta.subTitleKey) {
    const subTitle = to.params[to.meta.subTitleKey].toUpperCase()
    title = `${subTitle} - ${title}`
  }
  document.title = title
}
