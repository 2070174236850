import Vue from 'vue'

export default {
  FETCH_USER({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$httpUser.get('/api/auth/user/me')
        .then(resp => {
          commit('SET_USER', resp.data.data)
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FETCH_SETTINGS({ commit }) {
    Vue.prototype.$httpUser.get('/api/settings')
      .then(resp => {
        commit('SET_SETTINGS', resp.data.data)
      })
      .catch(() => {
        commit('SET_SETTINGS', null)
      })
  },
}
