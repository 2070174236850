<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <Snackbar />
  </component>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutLoading from '@/layouts/Loading.vue'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import Snackbar from '@/common/components/SnackBar.vue'

export default {
  components: {
    LayoutLoading,
    LayoutBlank,
    LayoutContent,
    Snackbar,
  },
  setup() {
    const { route } = useRouter()

    // eslint-disable-next-line func-names
    const resolveLayout = computed(function () {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      if (!this.$store.getters['user/user']) return 'layout-loading'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },

  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
  },

  created() {
    if (this.isAuthenticated) {
      this.FETCH_USER().catch(() => {
        this.LOGOUT(true)
      })
    }
  },

  methods: {
    ...mapActions('user', ['FETCH_USER']),
    ...mapActions('auth', ['LOGOUT']),
  },
}
</script>
