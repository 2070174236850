import Vue from 'vue'

export default {
  SET_CHARTS(state, payload) {
    state.charts = payload
  },
  SET_CHART(state, payload) {
    const { data } = payload
    const index = Vue.prototype.$_.findIndex(state.charts, e => e.id === data.id)
    if (index !== -1) {
      state.charts[index] = data
    } else {
      state.charts.push(data)
    }
  },
  REMOVE_CHART(state, index) {
    state.charts.splice(index, 1)
  },
  SET_CHART_STATUS(state, payload) {
    const { id, isActive } = payload
    const index = Vue.prototype.$_.findIndex(state.charts, e => e.id === id)
    state.charts[index].is_active = isActive
  },
}
