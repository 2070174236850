import Vue from 'vue'

export default {
  companyList: state => {
    const { companies } = state
    if (companies === null) return []

    const stocks = companies.map(company => ({
      text: `${company.symbol} - ${company.name}`,
      value: company.symbol,
    }))

    return Vue.prototype.$_.orderBy(stocks, ['value'], ['asc'])
  },

  companies: state => state.companies,

  getBySymbol: state => symbol => {
    if (!state.companies) {
      return null
    }
    const c = Vue.prototype.$_.find(state.companies, company => company.symbol === symbol.toUpperCase())
    if (typeof c === 'undefined') {
      return false
    }

    return c
  },
}
