import Vue from 'vue'

export default {
  FETCH_COMPANIES({ commit }) {
    Vue.prototype.$httpUser.get('/api/companies')
      .then(resp => {
        commit('SET_COMPANIES', resp.data.data)
      })
      .catch(() => {
        commit('SET_COMPANIES', [])
      })
  },
}
