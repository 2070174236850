<template>
  <v-snackbar
    v-model="show"
    transition="slide-y-reverse-transition"
    :color="color"
    :timeout="timeout"
  >
    {{ text }}
  </v-snackbar>
</template>

<script>

import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      text: '',
      color: '',
      show: false,
      timeout: 2500,
    }
  },

  created() {
    EventBus.$on('show-message', msg => {
      this.text = msg
      this.color = 'success'
      this.show = true
    })
    EventBus.$on('show-error', msg => {
      this.text = msg
      this.color = 'error'
      this.show = true
    })

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'SHOW_MESSAGE') {
        this.text = state.message.text
        this.color = state.message.color
        this.show = true
      }
    })
  },
}

</script>
