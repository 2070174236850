import Vue from 'vue'

export default {
  FETCH_INDICATORS({ commit }) {
    Vue.prototype.$httpUser.get('/api/indicators')
      .then(resp => {
        commit('SET_INDICATORS', resp.data.data)
      })
      .catch(() => {
        commit('SET_INDICATORS', [])
      })
  },
}
