import Vue from 'vue'

export default {
  FETCH_ARTICLE({ commit }) {
    Vue.prototype.$httpUser.get('/api/articles')
      .then(resp => {
        commit('SET_ARTICLES', resp.data.data)
      })
      .catch(() => {
        commit('SET_ARTICLES', [])
      })
  },
}
