import store from '../store'

export default function checkFeature(to, next) {
  if (to.matched.some(record => record.meta.is_pro)) {
    if (store.getters['user/isPro']) {
      next()
    } else {
      next({ path: '/' })
    }
  } else {
    next()
  }
}
