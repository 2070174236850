import Vue from 'vue'

export default {
  SET_ARTICLES(state, payload) {
    state.articles = payload
  },
  SET_ARTICLE(state, data) {
    const index = Vue.prototype.$_.findIndex(state.articles, e => e.id === data.id)
    Vue.set(state.articles, index, data)
  },
}
