const Module = () => import('./Module.vue')
const Thread = () => import('./views/Thread.vue')
const Company = () => import('./views/Company.vue')
const Login = () => import('./views/Login.vue')

const moduleRoute = {
  path: '/embed',
  component: Module,
  children: [
    {
      path: 'company/:symbol',
      name: 'embed-company',
      component: Company,
      meta: {
        layout: 'blank',
        embed: true,
      },
    },
    {
      path: 'comment/:id',
      name: 'embed-comment',
      component: Thread,
      meta: {
        layout: 'blank',
        embed: true,
      },
    },
    {
      path: 'login',
      name: 'embed-login',
      component: Login,
      meta: {
        layout: 'blank',
        embed: true,
      },
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
