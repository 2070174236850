const Module = () => import('./Module.vue')
const Index = () => import('./views/Index.vue')

const moduleRoute = {
  path: '/article',
  component: Module,
  children: [
    {
      path: '/article',
      name: 'article',
      component: Index,
      meta: {
        subTitle: 'Định giá chuyên sâu',
      },
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
