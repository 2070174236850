import Vue from 'vue'
import axios from 'axios'

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_API_HOST || '/',
})

const instanceUserApi = axios.create({
  baseURL: process.env.VUE_APP_API_HOST || '/',
})
Object.defineProperty(instanceUserApi, 'bearer', {
  set(token) {
    this.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  get() {
    return this.defaults.headers.common.Authorization
  },
})
instanceUserApi.bearer = localStorage.getItem('utkn')
Vue.prototype.$httpUser = instanceUserApi
