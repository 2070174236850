const Module = () => import('./Module.vue')
const Index = () => import('./views/Index.vue')

const moduleRoute = {
  path: '/portfolio',
  component: Module,
  children: [
    {
      path: '/portfolio',
      name: 'portfolio',
      component: Index,
      meta: {
        subTitle: 'Danh mục Trading',
      },
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
