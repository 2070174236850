export default {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload
  },
  SET_INDICATORS(state, payload) {
    state.indicators = payload
  },
}
