import Vue from 'vue'
import VueI18n from 'vue-i18n'

import vi from 'vuetify/lib/locale/vi'

Vue.use(VueI18n)

const messages = {
  vi: {
    ...require('@/locales/vi.json'),
    $vuetify: vi,
  },
}

export default new VueI18n({
  // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locale: 'vi',
  fallbackLocale: 'vi',
  messages,
})
