const Module = () => import('./Module.vue')
const Login = () => import(/* webpackChunkName: "login" */ './views/Login.vue')
const Register = () => import(/* webpackChunkName: "register" */ './views/Register.vue')
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ './views/ForgotPassword.vue')
const ResetPassword = () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue')
const Verify = () => import(/* webpackChunkName: "verify-email" */ './views/VerifyEmail.vue')

const moduleRoute = {
  path: '/auth',
  component: Module,
  children: [
    {
      path: 'login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'blank',
        guest: true,
      },
    },
    {
      path: 'register',
      name: 'register',
      component: Register,
      meta: {
        layout: 'blank',
        guest: true,
      },
    },
    {
      path: 'forget-password',
      name: 'forget-password',
      component: ForgotPassword,
      meta: {
        layout: 'blank',
        guest: true,
      },
    },
    {
      path: 'reset-password/e/:email/t/:token',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        layout: 'blank',
        guest: true,
      },
    },
    {
      path: 'verify/i/:id/h/:hash/e/:expires/s/:signature',
      name: 'verify',
      component: Verify,
      meta: {
        layout: 'blank',
        guest: true,
      },
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
