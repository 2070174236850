import Vue from 'vue'
import VueRouter from 'vue-router'
import checkAuth from '../middlewares/check-auth'
import checkFeature from '../middlewares/feature'
import setTitle from '../middlewares/title'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'chart/single',
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import('@/views/dashboard/Dashboard.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     subTitle: 'Dashboard',
  //   },
  // },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
      subTitle: 'Page Not Found',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  setTitle(to)
  checkAuth(to, next)
  checkFeature(to, next)
})

export default router
