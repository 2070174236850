import Vue from 'vue'

export default {
  user: state => state.user,
  isPro: state => state.user?.is_pro || false,
  chartOptions: state => ({
    dataLabels: Vue.prototype.$_.get(state.user, 'settings.chart.options.data_labels', true),
    legend: Vue.prototype.$_.get(state.user, 'settings.chart.options.legend', true),
  }),
  chartList: (state, getters, rootState) => {
    const activeCharts = Vue.prototype.$_.get(state.user, 'settings.chart.actives', {})
    rootState.chart.charts.forEach(chart => {
      if (typeof activeCharts[chart.id] === 'undefined') {
        activeCharts[chart.id] = true
      }
    })

    return activeCharts
  },
  chartDelay: state => (state.settings ? state.settings.chart.delay : null),
  subscribed: state => (state.user ? state.user.settings.subscribed : false),
}
