<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ user.name || '' }}
          </span>
          <!-- <small class="text--disabled text-capitalize">Admin</small> -->
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Pricing -->
      <v-list-item>
        <v-list-item-icon class="me-2">
          <v-icon
            size="22"
            color="warning"
          >
            mdi-alpha-s-circle-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            {{ user.fund || 0 }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Pay up -->
      <v-list-item
        link
        @click="$emit('qr')"
      >
        <v-list-item-icon
          class="me-2"
        >
          <v-icon size="22">
            mdi-credit-card-plus-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Nạp tài khoản</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Settings -->
      <v-list-item
        link
        :to="{ name: 'setting' }"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            mdi-cog-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('setting') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            mdi-logout-variant
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click="LOGOUT(true)">
            Đăng xuất
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import { mapActions } from 'vuex'

export default {

  computed: {
    user() {
      return this.$store.getters['user/user'] || {}
    },
  },

  methods: {
    ...mapActions('auth', ['LOGOUT']),
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
