import Vue from 'vue'

export default {
  SET_TOKEN(state, payload) {
    state.token = payload
    Vue.prototype.$httpUser.bearer = payload
  },
  CLEAR_TOKEN(state) {
    state.token = ''
  },
}
