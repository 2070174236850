<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-slide-x-transition>
          <v-img
            :src="require('@/assets/images/logos/logo.png')"
            alt="logo"
            contain
            eager
            class="app-logo"
          />
        </v-slide-x-transition>
        <v-chip
          color="error"
          x-small
          class="px-1 ms-1"
        >
          PRO
        </v-chip>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        v-for="(item, key) in items"
        :key="key"
        :title="$t(item.title)"
        :to="{ name: item.to }"
        :icon="item.icon"
        :class="item.class"
      ></nav-menu-link>
    </v-list>

    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        :title="$t('tutorial')"
        href="https://simpleinvest.finance/tutorial"
        icon="mdi-file-document-multiple-outline"
        target="_blank"
      ></nav-menu-link>
      <nav-menu-link
        v-for="(item, key) in bottomLinks"
        :key="key"
        :title="$t(item.title)"
        :to="{ name: item.to }"
        :icon="item.icon"
      ></nav-menu-link>
    </v-list>
    <v-btn
      color="error"
      class="upgrade-banner product-upgrade-to-pro"
      to="/plan"
    >
      Nâng cấp tài khoản
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      links: [
        // {
        //   title: 'dashboard',
        //   icon: 'mdi-home-outline',
        //   to: 'dashboard',
        // },
        {
          title: 'chart',
          icon: 'mdi-chart-box-outline',
          to: 'chart',
        },
        {
          title: 'chart-compare',
          icon: 'mdi-chart-box-plus-outline',
          to: 'chart-compare',
          is_pro: true,
          class: 'd-none d-md-flex',
        },
        {
          title: 'chart-trading',
          icon: 'mdi-chart-line',
          to: 'chart-trading',
        },
        {
          title: 'portfolio',
          icon: 'mdi-folder-text-outline',
          to: 'portfolio',
        },
        {
          title: 'article',
          icon: 'mdi-newspaper',
          to: 'article',
        },
        {
          title: 'fa-filter',
          icon: 'mdi-filter-outline',
          to: 'company',
          is_pro: true,
        },
      ],

      bottomLinks: [
        {
          title: 'setting',
          icon: 'mdi-cog-outline',
          to: 'setting',
        },
      ],
    }
  },
  computed: {
    items() {
      const isPro = this.$store.getters['user/isPro']
      const result = []
      this.links.forEach(item => {
        if (isPro || (!isPro && !item.is_pro)) {
          result.push(item)
        }
      })

      return result
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
  width: 170px;
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 75px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
