const Module = () => import('./Module.vue')
const User = () => import('./views/User.vue')

const moduleRoute = {
  path: '/user',
  component: Module,
  children: [
    {
      path: '/',
      name: 'user',
      component: User,
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
