import Vue from 'vue'

export default {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload
  },
  SET_ACTIVE_CHARTS(state, payload) {
    Vue.prototype.$_.set(state.user, 'settings.chart.actives', payload)
  },
  CHARGE(state, price) {
    state.user.fund -= price
  },
  SET_SUBSCRIPTION(state, payload) {
    state.user.settings.subscribed = payload
  },
}
