import Vue from 'vue'

export default {
  FETCH_COMMENT({ commit, state }) {
    Vue.prototype.$http.get(`/api/public-articles/${state.articleId}/comments`)
      .then(resp => {
        commit('SET_THREAD', resp.data.data)
      })
      .catch(() => {
        commit('SET_THREAD', {})
      })
  },
  CREATE_COMMENT({ commit, state }, { path, content }) {
    Vue.prototype.$httpUser.post(`/api/public-articles/${state.articleId}/comments`, { path, content })
      .then(resp => {
        commit('ADD_COMMENT', {
          comment: resp.data.data,
          path,
        })
      })
      .catch(() => {})
  },
  UPDATE_COMMENT({ commit, state }, { path, content }) {
    Vue.prototype.$httpUser.put(`/api/public-articles/${state.articleId}/comments`, { path, content })
      .then(resp => {
        commit('UPDATE_COMMENT', {
          comment: resp.data.data,
          path,
        })
      })
      .catch(() => {})
  },
  DELETE_COMMENT({ commit, state }, { path }) {
    Vue.prototype.$httpUser.delete(`/api/public-articles/${state.articleId}/comments`, { data: { path } })
      .then(() => {
        commit('DELETE_COMMENT', { path })
      })
      .catch(() => {})
  },
}
