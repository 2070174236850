import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import preset from './default-preset/preset'
import i18n from '@/common/i18n'
import '@mdi/font/css/materialdesignicons.css'
import 'tiptap-vuetify/dist/main.css'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
})

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg',
})

export default vuetify
