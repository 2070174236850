import store from '../store'

export default function checkAuth(to, next) {
  if (to.matched.some(record => record.meta.guest)) {
    if (store.getters['auth/isAuthenticated']) {
      next({ path: '/' })
    } else {
      next()
    }
  } else if (store.getters['auth/isAuthenticated'] || to.matched.some(record => record.meta.embed)) {
    next()
  } else {
    next({ name: 'login' })
  }
}
