<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <v-autocomplete
            v-model="symbol"
            :items="companyList"
            rounded
            dense
            outlined
            prepend-inner-icon="mdi-magnify"
            append-icon=""
            class="app-bar-search flex-grow-0"
            hide-details
            placeholder="Nhập mã chứng khoán"
            :menu-props="{ rounded: true, maxWidth: '290' }"
            @change="toCompany"
          ></v-autocomplete>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>

          <v-btn
            icon
            small
            class="ms-3"
            @click="isSupportOpen = !isSupportOpen"
          >
            <v-icon>
              mdi-chat-question-outline
            </v-icon>
          </v-btn>

          <!-- <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              mdi-bell-outline
            </v-icon>
          </v-btn> -->
          <app-bar-user-menu @qr="showQr"></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <!-- <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2021 <a
              href="https://themeselection.com"
              class="text-decoration-none"
              target="_blank"
            >ThemeSelection</a></span>
          <span class="d-sm-inline d-none">
            <a
              href="https://themeselection.com/products/category/download-free-admin-templates/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Freebies</a>
            <a
              href="https://themeselection.com/blog/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Blog</a>
            <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free/blob/main/LICENSE"
              target="_blank"
              class="text--secondary text-decoration-none"
            >MIT Licence</a>
          </span>
        </div>
      </div>
    </v-footer> -->
    <QR ref="qr" />
    <ChatBox v-show="isSupportOpen" />
  </v-app>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import QR from '@/components/QRCode.vue'
import ChatBox from '@/components/ChatBox.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    QR,
    ChatBox,
  },

  data() {
    return {
      isDrawerOpen: null,
      isSupportOpen: true,
      symbol: null,
    }
  },

  computed: {
    ...mapGetters('company', ['companyList']),
    items() {
      return this.$store.getters['company/list']
    },
  },

  created() {
    this.FETCH_COMPANIES()
  },

  methods: {
    ...mapActions('company', ['FETCH_COMPANIES']),
    toCompany() {
      this.$router.replace({ name: 'company-summary', params: { symbol: this.symbol } })
      this.$nextTick(() => {
        this.symbol = null
      })
    },
    showQr() {
      this.$refs.qr.show()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
  .v-app-bar__nav-icon {
    margin-left: -8px;
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
