const Module = () => import('./Module.vue')
const Index = () => import('./views/Index.vue')

const moduleRoute = {
  path: '/plan',
  component: Module,
  children: [
    {
      path: '/',
      name: 'plan',
      component: Index,
      meta: {
        subTitle: 'Gói sản phẩm',
      },
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
