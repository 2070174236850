const Module = () => import('./Module.vue')
const Setting = () => import('./views/Setting.vue')

const moduleRoute = {
  path: '/setting',
  component: Module,
  children: [
    {
      path: '/setting',
      name: 'setting',
      component: Setting,
      meta: {
        subTitle: 'Cài đặt',
      },
    },
    {
      path: ':tab',
      name: 'setting-tab',
      component: Setting,
      meta: {
        subTitle: 'Cài đặt',
      },
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
