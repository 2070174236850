import Vue from 'vue'

export default {
  FETCH_CHARTS({ commit }) {
    // const charts = localStorage.getItem('charts')
    // if (charts) {
    //   commit('SET_CHARTS', JSON.parse(charts))
    // } else {
    Vue.prototype.$httpUser.get('/api/charts')
      .then(resp => {
        commit('SET_CHARTS', resp.data.data)
      })
      .catch(() => {
        commit('SET_CHARTS', null)
      })
    // }
  },
}
