const Module = () => import('./Module.vue')
const Single = () => import('./views/Single.vue')
const Double = () => import('./views/Double.vue')
const Trading = () => import('./views/Trading.vue')

const moduleRoute = {
  path: '/chart',
  component: Module,
  redirect: '/chart/single',
  children: [
    {
      path: 'single',
      name: 'chart',
      component: Single,
      meta: {
        subTitle: 'Biểu đồ',
      },
    },
    {
      path: 'compare',
      name: 'chart-compare',
      component: Double,
      meta: {
        is_pro: true,
      },
    },
    {
      path: 'trading',
      name: 'chart-trading',
      component: Trading,
    },
  ],
}

export default router => {
  router.addRoute(moduleRoute)
}
