function findComment(path, parent) {
  if (path.length === 0) return parent
  const id = path.shift()
  const comment = parent.comments.find(item => item.id === id)

  return findComment(path, comment)
}

export default {
  SET_ARTICLE(state, id) {
    state.articleId = id
  },
  SET_THREAD(state, payload) {
    state.thread = payload
  },
  ADD_COMMENT(state, { comment, path }) {
    findComment(path, state.thread).comments.unshift(comment)
  },
  UPDATE_COMMENT(state, { comment, path }) {
    const c = findComment(path, state.thread)
    c.content = comment.content
  },
  DELETE_COMMENT(state, { path }) {
    const id = path.pop()
    const c = findComment(path, state.thread)
    c.comments = c.comments.filter(item => item.id !== id)
  },
}
