<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="600"
    >
      <v-card>
        <v-card-text class="text-center">
          <img
            :height="height"
            :src="src"
            alt="QR"
          >
          <v-row dense>
            <v-col
              v-for="(v, k) in values"
              :key="k"
            >
              <v-btn
                color="success"
                outlined
                @click="setAmount(v)"
              >
                {{ (v/1000) }}K
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { EventBus } from '@/event-bus'

export default {

  data: () => ({
    dialog: false,
    domain: 'https://img.vietqr.io/image',
    bank: 'vietcombank',
    account: '9375205621',
    accountName: 'LE PHU THINH',
    template: 'compact2.jpg',
    amount: 1000000,
    values: [1000000, 2000000, 3000000, 5000000, 10000000],
  }),

  computed: {
    src() {
      const user = this.$store.getters['user/user'] || null
      if (!user) return ''

      const info = `SI ${user.name} ${user.email} ${user.phone}`

      const params = new URLSearchParams({
        amount: this.amount,
        accountName: this.accountName,
        addInfo: info,
      })

      return `${this.domain}/${this.bank}-${this.account}-${this.template}?${params.toString()}`
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 350
        case 'sm': return 500
        default: return 600
      }
    },
  },

  created() {
    EventBus.$on('show-qr', this.show)
  },

  methods: {
    show() {
      this.dialog = true
    },
    setAmount(value) {
      this.amount = value
    },
  },
}
</script>
